*{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
body {
   background-color: #272727;
  color: #feeeee;
  font-family: "Rubik", sans-serif;
       
        font-size: 14px;
        font-weight: normal;
       background-color: #171717;
       color: white;
       height: 100%;
        
}
a {
  text-decoration: none;
  color: inherit;
}
*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  
}

/* Track */
*::-webkit-scrollbar-track {
  background: #777; 
}
 
/* Handle */
*::-webkit-scrollbar-thumb {
  background: rgba(36, 45, 73, 0.65); 
  border-radius: 8px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: rgba(36, 45, 73, 0.65); 
}
.not-found {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../src/images/login.jpg);
  background-size: cover;
}
.login-form-wrapper {
  border-radius: 10px;

  padding: 20px;
  margin: 20px auto;
  background: white;
  max-width: 500px !important;
  width: 100%;
}

.login-form-wrapper .title ,.title-small{
  font-size: 20px;
  color: #fcfbfb;
  margin-bottom: 20px;

 
}
.input-wrapper {
  margin-bottom: 20px;
  position: relative;
}
.form-group {
  margin-bottom: 20px;
}
.login-form-wrapper input , .login-form-wrapper select{
  border: 1px solid #fcfbfb;
  color: #333;
  font-size: 14px;
  border-radius: 10px;
  background-color: #fcfbfb;
  padding: 10px 20px;
  display: block;
  width: 100%;
}
input:focus{
  outline: none;
  box-shadow: none;
}
.button-gradient {
  background-image: linear-gradient(to right, #feeeee, #485563, #2b5876, #4e4376);
  box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75);
  padding: 10px 20px;
 border: none;
 border-radius: 10px;
 min-width: 120px;
 color: white;
}
.select-company {
  display: flex;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.select-company .company i {
  font-size: 30px;
}
.select-company .company {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 10px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  padding: 20px;
  min-width: 100px;
  border-radius: 10px;
  background-image: linear-gradient(to right, #feeeee, #485563, #2b5876, #4e4376);
}
.create-company-form {
  max-width: 1000px;
  width: 100%;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  padding: 20px;
  border-radius: 10px;
  margin: 20px auto;
}
.create-company-form label {
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
}
select:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
.create-company-form input,.create-company-form select {
  border: 1px solid #fcfbfb;
  font-size: 14px;
  color: #333;
  border-radius: 10px;
  background-color: #fcfbfb;
  padding: 10px 20px;
  display: block;
  width: 100%;
  height: 40px;
}
.radio-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.create-company-form .radio-wrapper  label {
  margin-bottom: 0px;
}
.create-company-form .radio-wrapper input {
  width: 22px;
  display: inline;
  height: 22px;
}
.loader-wrapper,.loader-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 99999;
  background-color: rgba(0,0,0,0.85);
  overflow: hidden;
  left: 0px;
  top: 0px;
}
.persons-wrapper {
  background-color: rgba(0,0,0,0.85);
  border-radius: 10px;
  padding: 30px;
  margin: 30px auto;
}
.persons-wrapper .title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}
.persons-wrapper .subtitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
}
.persons-wrapper .item {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 10px;
}
.persons-wrapper .wrapper {
  margin-bottom: 12px;
  border-bottom: 1px solid #ddd;
}
.persons-wrapper .item  .name {
  font-weight: 600;
}
.loader-wrapper .loader,.loader-parent .loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #fff;
}
  .loader:before,
  .loader:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    transform: rotateX(70deg);
    animation: 1s spin linear infinite;
  }
  .loader:after {
    color: #FF3D00;
    transform: rotateY(70deg);
    animation-delay: .4s;
  }

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: .2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: .2em .2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 .2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -.2em .2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -.2em -.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: .2em -.2em 0 0 currentcolor;
  }
}
.main-dashboard {
 display: flex;
}
.main-dashboard .right {
  width: calc(100% - 230px);
  padding: 20px;
}
.sidebar {
  height: 100vh;
 background-color: #171717;
  padding-top: 20px;
  overflow-y: auto;
  position: sticky;
  max-width: 230px;
  width: 100%;
  border-right: 2px solid #ddd;
  top: 10px;
  padding-bottom: 30px;
}
.sidebar ul{
  padding: 0px;
  padding-left: 0px !important;
  margin: 0px;
  list-style: none;
}
.title_medium {
  font-size: 20px;
}
.sidebar ul li {
  width: 100%;
}
.sidebar ul li a {
  display: flex;
  gap: 5px;
  width: 100%;
  font-size: 14px;
  padding: 17px 10px;
  margin-bottom: 0px;
  align-items: center;
  line-height: 1.2;
 color: #fff;
 border-bottom: 1px solid #ddd;
}
.sidebar ul li .logout {
  display: flex;
  gap: 5px;
  padding: 8px 10px;
  margin-bottom: 0px;
  align-items: center;
 color: #fff;
 border-bottom: 1px solid #ddd;
}
.sidebar ul li a img {
height: 18px;
width: 20px;
object-fit: contain;
}
.container-fluid {
  padding-left: 0px !important;
}
.compnies-list  {
  padding: 0px;
  padding-left: 0px !important;
  margin: 0px;
  list-style: none;
}
.actions {
  display: flex;
  gap: 12px;
  align-items: center;
}
.actions svg ,.actions i{
  cursor: pointer;
  font-size: 18px;
}
 .fa-trash-can {
  color: #feeeee
}
.dynamic_fields {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
}
.dynamic_fields .form-group {
  width: 100%;
}
.dynamic_fields .button,.button-add {
  height: 40px;
  box-shadow: none !important;
  background: #4e4376;
  color: white;
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
}
.fa-pen-to-square {
  color: #fefefe;
}
.fa-eye {
  color: #fefefe;
}
.tabble-title {
  color: #feeeee;
  font-size: 25px;
  margin-bottom: 15px;
  text-align: center;
  font-weight: 600;
}
.table-responsive td {
  padding-left: 0px !important;
}
.company-details-wrapper .user-wrapper {
  display: flex;
  gap: 12px;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  padding: 16px;
  border-radius: 10px;
}
.company-details-wrapper .user-wrapper img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}
.company-details-wrapper .user-wrapper .name {
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #feeeee;
  gap: 5px;
  margin-bottom: 4px;
}
.company-details-wrapper .user-wrapper .name  i {
  font-size: 13px;
  width: 15px;
}
.company-details-wrapper .title-main,.title-main {
  font-size: 22px;
  color: #feeeee;
  margin-bottom: 15px;
  text-align: center;
}
.company-detail-item {
  box-shadow:   9px 7px 16px 3px #090808, 1px 9px 20px #1a1919;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;
  text-align: center;
}
.categories-wrapper {
  display: flex;
  flex-wrap: nowrap;
}
.categories-wrapper select ,.categories-wrapper input{
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.modal-fixed {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(240, 240, 240, 0.3);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
}
.css-1nmdiq5-menu div{
background-color: #090808 !important;
color: white !important;
cursor: pointer;
}
.css-1nmdiq5-menu div:hover {
  background-color: #093023 !important;
}
.ql-editor br {
  display: inline-block !important;
}
p:empty,p br {
  display: none !important;
}
p {
  margin-bottom: 8px !important;
}
.modal-fixed .form-modal {
  max-width: 760px;
  background-color: #171717;
  width: 100%;
  border-radius: 10px;
  position: relative;
  padding: 10px;
  max-height: 80vh;
  overflow-y: auto;
}
.modal-fixed .form-modal .icon {
  
 cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
}
.modal-fixed .form-modal .icon i {
  color: #fefefe;
  font-size: 25px;
}
.modal-fixed .create-company-form {
  box-shadow: none;
}
.modal-fixed .form-modal .title {
  font-size: 16px;
  margin-bottom: 10px;
  text-align: center;
}
.imagee-td img {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  object-fit: cover;
}
.product-image img {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  object-fit: cover;
}
.categories-wrapper button {
  min-width: 120px;
  white-space: nowrap;
  font-size: 12px;
  height: 50px;
  background-color: #171717;
  color: white;
  border: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: none;
}
.pointer {
  cursor: pointer;
}
.company-detail-item .title {
  font-size: 16px;
  font-weight: 600;
  color: #feeeee;
  margin-bottom: 10px;
}
.company-detail-item img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  object-fit: cover;
}
.company-detail-item .value {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #feeeee;
}
.dashboard-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

}
.dashboard-main .dashboard-inner {
  padding: 30px;
  display: flex;
  align-items: center;
  gap: 30px;
  max-width: 1000px;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 70vh;
}
.term-tab {
  display: flex;
  align-items: center;
  margin: 40px 0px;
  justify-content: center;
  gap: 20px;
}
.term-tab .button {
  min-width: 300px !important;
  height: 50px;
}
.term-tab .button.active {
  background-color: #2b5876;
}
.dashboard-inner .dashboard-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-width: 150px;
  height: 150px;
  border-radius: 10px;
  color:#feeeee;
  font-weight: bold;
  box-shadow: -5px -5px 20px #212121,  5px 5px 20px #141414;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-weight: 600;

  background-color: #272727;
  border: none;
}
.dashboard-inner .dashboard-item svg {
  font-size: 40px;
}
.dashboard-inner .dashboard-item:hover {
  box-shadow: inset 1px 1px 2px #141414, inset -1px -1px 2px #212121;
}
.dashboard-inner .dashboard-item:active {
  box-shadow: inset 1px 1px 2px #141414, inset -1px -1px 2px #212121;
}



.css-b62m3t-container {
  width: 100%;
}
.button {
  color:#feeeee;
  font-weight: bold;
  box-shadow: -5px -5px 20px #212121,  5px 5px 20px #141414;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-weight: 600;
  padding: 10px 30px;
  border-radius: 10px;
  background-color: #272727;
  border: none;
  display: inline-block;
}
.button.button-small {
  font-size: 10px;
  padding: 5px 10px;
  border-radius: 5px;
}
.button:hover {
  box-shadow: -2px -2px 5px #212121, 2px 2px 5px #141414;
}
.button:active {
  box-shadow: inset 1px 1px 2px #141414, inset -1px -1px 2px #212121;
}
.button-icon {
  width: 32px;
  height: 32px;
  color:#feeeee;
  font-weight: bold;
  box-shadow: -5px -5px 20px #212121,  5px 5px 20px #141414;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-left: 10px;
}
.button-icon:hover {
  box-shadow: -2px -2px 5px #212121, 2px 2px 5px #141414;
}
.button-icon:active {
  box-shadow: inset 1px 1px 2px #141414, inset -1px -1px 2px #212121;
}
a,a:hover {
  color: #feeeee;
  text-decoration: none;
}
.form-wrapper {
  margin: 15px auto;
  background-color: #272727;
  box-shadow: -5px -5px 20px #212121,  5px 5px 20px #141414;
  border-radius: 5px;
  padding: 20px 50px;
  max-width: 1200px;
  width: 95%;
  margin: 30px auto;
}
.form-wrapper .title  {
  font-size: 30px;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 600;
  color:#feeeee;
}
.css-1p3m7a8-multiValue div {
  font-size: 10px;
}
.form-wrapper .input,.form-wrapper select ,.form-modal .input ,.form-modal select,.input,select {
  background-color: #272727;
    color: #feeeee;
    caret-color: #feeeee;
  
    box-shadow:  inset 4px 4px 10px #141414, inset -7px -8px 10px #212121;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    appearance: none;
    -webkit-appearance: none;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    height: 50px;
  
}
.css-13cymwt-control {
  background: #272727 !important;
  color: #feeeee !important;
  caret-color: #feeeee;
  box-shadow: inset 4px 4px 10px #141414, inset -7px -8px 10px #212121;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  appearance: none;
  -webkit-appearance: none;
  border: none !important;
  padding: 7px 10px !important;
  border-radius: 5px !important;
  min-height: 50px;
  height: 100%;
}
.css-19bb58m ,.css-1dimb5e-singleValue{
  color: white !important;
}
.form-wrapper .input:-webkit-autofill {
  -webkit-box-shadow:  inset 4px 4px 10px #141414, inset -7px -8px 10px #212121 !important;
  box-shadow:  inset 4px 4px 10px #141414, inset -7px -8px 10px #212121;
  color: white;
}


.form-wrapper .input[type="password"]:-webkit-autofill,.form-wrapper .input[type="password"]:-webkit-autofill:hover,.form-wrapper .input[type="password"]:-webkit-autofill:focus {
  -webkit-box-shadow:inset 4px 4px 10px #141414, inset -7px -8px 10px #212121 !important;
  box-shadow:  inset 4px 4px 10px #141414, inset -7px -8px 10px #212121;
  color: white;
}

.form-wrapper .input:hover ,.form-wrapper .input:focus,.form-wrapper select:hover ,.form-wrapper select:focus {
  border: none;
  box-shadow:  inset 6px 6px 14px #141414, inset -9px -11px 14px #212121;
  outline: none;
}
.form-wrapper .subtitle {
  display: block;
  font-weight: 600;
  font-size: 20px;
  color: #feeeee;
  margin-bottom: 8px;
}
.form-wrapper label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  color: #feeeee;
  margin-bottom: 8px;
}
.dark-theme {
  background-color: #171717;
  color: white;
}
.create-company {
  position: relative;
}
.create-company .back {
  position: absolute;
  top: 15px;
  left: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: white;
  gap: 5px;
}
.table-responsive {
  padding: 30px;
  margin: 15px auto;
  box-shadow:   9px 7px 16px 3px #090808, 1px 9px 20px #1a1919;
  border-radius: 15px;
}
.table{
  color:white;
}
.product-bar {
  display: flex;
 overflow-x: auto;
  padding: 10px 10px;
  border-radius: 10px;
  box-shadow:   9px 7px 16px 3px #090808, 1px 9px 20px #1a1919;

  gap: 20px;
  align-items: center;
  font-size: 16px;
  margin: 22px auto;
  font-weight: bold;
}
.product-bar a {
  color: white;
  text-decoration: none;
  font-size: 12px;
  flex-shrink: 0;
}
.taxes-wrapper {
  padding: 30px;
}
.taxes-wrapper .tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-bottom: 20px;

}
.taxes-wrapper .tabs .tab .button.active {
  border: 1px solid #000;
  background-color: #2b5876;
}
.taxes-wrapper .form-wrapper-tax {
  max-width: 600px;
  padding: 30px;
  border-radius: 10px;
  box-shadow:   9px 7px 16px 3px #090808, 1px 9px 20px #1a1919;
}
.label-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  margin-bottom: 10px;
}
.label-flex .edit {
  cursor: pointer;
}
.relative {
  position: relative;
  padding: 10px 10px;
}
.relative .close {
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
  flex-shrink: 0;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0px;

  background-color: #2b5876;
}
.relative .close  svg {
  color:black;
}

.users-wrapper .table-button {
  padding: 8px;
  background-color: #12372A;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  border: none;
  color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  cursor: pointer;
}
.users-wrapper .title {
  font-size: 20px;
  color: white;
  margin-bottom: 30px;
  font-weight: bold;
}
.users-wrapper .title span {
  color: #93c602;
}
.Toastify__toast-container--top-right .Toastify__toast-theme--light {
  background-color: #12372A;
  box-shadow: -5px -5px 20px #12372A,  5px 5px 20px #141414;
  color: #ddd;
}
.css-13cymwt-control,.css-t3ipsp-control {
  background: #141414!important;
  color: #ddd;
  caret-color: #ddd;

  background-color: #141414 !important;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
}
.css-1nmdiq5-menu{
  background-color: #141414 !important;
  color: white !important;
}
.css-1p3m7a8-multiValue{
  background-color: #12372A !important;
  color: white !important;
  padding: 4px 8px;
  /* box-shadow: -2px -2px 5px #12372A, 2px 2px 5px #141414; */
}
.css-1p3m7a8-multiValue div {
  color: white !important;
}
.fdwccY,.elQTsw,.bwROKh {
  background-color: #12372A !important;
  color: #ddd !important;
  border-bottom-color:#ddd !important;
}
.bwROKh button {
  color: #ddd;
  fill: #ddd;
}
.hSQKHD:not(:last-of-type),.hSQKHD {
  background-color: #12372A !important;
  color: #ddd !important;
}
.button-icon {
  width: 32px;
  height: 32px;
  color:#ddd;
  flex-shrink: 0;
  font-weight: bold;
  box-shadow: -5px -5px 20px #12372A,  5px 5px 20px #141414;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-left: 10px;
}
.button-icon:hover {
  box-shadow: -2px -2px 5px #12372A, 2px 2px 5px #141414;
}
.button-icon:active {
  box-shadow: inset 1px 1px 2px #141414, inset -1px -1px 2px #12372A;
}
.permissions-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 20px auto;
  gap: 30px;
}
.permissions-wrapper .checkboxx {
  flex-basis: 20%;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  flex-shrink: 0;
  cursor: pointer;

}
.permissions-wrapper .checkboxx label {
  margin: 0px;
  cursor: pointer;
}
.permissons-allowed ul{
  font-size: 14px;
}
.permissions-wrapper .checkboxx input {
  width: 20px;
  cursor: pointer;
  flex-shrink: 0;
  height: 20px;
}
a,a:hover {
  color: #ddd;
  text-decoration: none;
}
.brand-categories {
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
  list-style-type: disc;
  gap: 8px;
}
.brand-categories li {
  font-size: 12px;
  margin: 0px;
  text-align: left;
}
.css-13cymwt-control,.css-t3ipsp-control {
  background-color: #12372A;
  color: #ddd;
  caret-color: #ddd;

  background-color: #141414 !important;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
}
.css-1nmdiq5-menu{
  background-color: #141414 !important;
  color: white !important;
}
.css-1p3m7a8-multiValue{
  background-color: #12372A !important;
  color: white !important;
  padding: 4px 8px;
  /* box-shadow: -2px -2px 5px #12372A, 2px 2px 5px #141414; */
}
.eGxbrW  ,.dODGfY,.bRGCiY{
  background-color: #12372A !important;
  color: white !important;
}
.css-1p3m7a8-multiValue div {
  color: white !important;
}
.hPcKRQ div:first-child {
  white-space: pre-wrap !important;
} 
.bOmZm {
  border: none !important;
  height: auto !important;
  padding: 5px !important;
  margin: 5px 20px !important;
}
.images-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.images-preview .single-image {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
}  
.images-preview.detail-images .single-image {
  width: 300px;
  height: 300px;
}
.images-preview .single-image .text {
color: white;
font-size: 13px;
font-weight: bold;
word-break: break-all;
}
.images-preview .single-image img{
 
  width: 100px;
  height: 100px;
  border-radius: 5px;
  object-fit: cover;
} 
.images-preview.detail-images .single-image img {
  width: 300px;
  height: 300px;
  border-radius: 5px;
  object-fit: cover;
}
.images-preview .single-image.files {
  width: 200px;
  padding: 10px;
}
.images-preview.detail .single-image.files {
  width: 300px;
}
.sc-dhKdPU.hSQKHD.rdt_TableRow {
  padding: 3px 20px;
}
.images-preview .single-image .close {
  position: absolute;
  top: 5px;
  right:5px;
  padding: 0px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: white;
  z-index: 99;
  display: flex;
  justify-content: center;
  color: #000;
  align-items: center;
  cursor: pointer;
}
.single-simulation,.view-box {
  padding: 30px;
  background-color: #12372A;
  margin-bottom: 40px;
  box-shadow: -2px -2px 5px #12372A, 2px 2px 5px #141414;
  border-radius: 20px;
  position: relative;
}
.single-simulation .close {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 30px;
  width: 30px;
  background-color: #093023;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
}
.single-simulation .input {
  margin-bottom: 20px;
}
.kdoubu,.dBiUCQ,.gcBbvz,.hovPcd,.gcBbvz,.dxE {
  background-color: #333 !important;
  color: white !important;
}
.btJbqV:disabled {
  color: #999 !important;
  fill: #999 !important;
}
.btJbqV {
  color: white !important;
  fill: white !important;
}